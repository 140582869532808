

var focusedElBeforeOpen = null;
var cartDialog ; 
var cartDialogEl;
var cartOverlay;

var SHIPMENT_UPDATED_MSG = "שיטת משלוח עודכנה";



jQuery(document).ready(function ($) {
    /**
     * 
     *  elemntor pro menu cart widget
     * 
    */



     var cartWidget = $('.elementor-menu-cart__wrapper .elementor-lightbox .elementor-menu-cart__main');
    
    if (cartWidget.length){

       

        setCloseButton();
        setCartWidgetasDialog();

        $("#elementor-menu-cart__toggle_button").on("click",function(){ 
        
         
            setTimeout( function(){

                cartDialog.open();
    
    
            } ,500);
      
        
        });
    }
 /*
    $( document.body ).on( 'added_to_cart', toogleCartDialog );
   */
    $( document.body ).on( 'added_to_cart', function(event){
      
        setTimeout( function(){

            cartDialog.open();


        } ,500);

    })

   

    /***
     * 
     *  move this to page:
     * 
     */

     var orderform = $("form.woocommerce-ordering");

     if(orderform.length){

            //console.log("orderform"+orderform);

            $(orderform).each(function(index){
                var id = "order_select_"+index;
                
                $(this).find("select").attr("id",id);

                $(this).find("select").before('<label for="'+id+'">סידור מוצרים <span class="sr-only">העמוד יטען מחדש עם הבחירה<span></label>');

                $(this).find("select").removeAttr("aria-label");


            })
     }
     
    /**
     * 
     * Add id="content" to main div so "skip to content" link will work
     * 
     */
    var pageMainContentElm =  $( "div.product[ data-elementor-type='product-archive']" );

    if ( !setMainID( pageMainContentElm )  ){
        pageMainContentElm = $( "div.post[ data-elementor-type='single-post']" );

        if ( !setMainID( pageMainContentElm )  ){
            pageMainContentElm = $( "div.product[ data-elementor-type='product']" );
            setMainID( pageMainContentElm );

        }
    }
     

    function setMainID ( mainElm ){

      

        if (mainElm.length){

            var contentID = $(mainElm).attr("id")

            //console.log("single element post page"+ contentID );
          
            if ( !contentID || contentID =="" || contentID == undefined){

                $(mainElm).attr("id","content");
                $(mainElm).attr("tabindex","-1");

                return true;

            }
            


        } 
       
        return false;

    }


    /************************************* */

    function toogleCartDialog(){

        var cartOpen = $('.elementor-menu-cart__wrapper .elementor-lightbox').attr("aria-hidden");

        //console.log(" cartOpen = "+ cartOpen + " "+typeof(cartOpen));

        if( cartOpen == "true" ){
            cartDialog.open();
        } 


    }

    function setCloseButton(){

        //$(cartWidget)
        /*
        $('.dialog-widget .dialog-widget-content .dialog-message').attr({
            "role":"alert", "aria-live":"assertive"});
        
        $('.elementor-menu-cart__close-buttonn').attr({
            "role":"button",
            "tabindex":"0"
        }   );
*/
        $('.elementor-menu-cart__close-buttonn').attr("role","button");
            

        $('.elementor-menu-cart__close-button').prepend('<button><span class="sr-only">סגור</span></button>');

        //$('.dialog-close-button i').attr("aria-hidden","true");
        
        $('.elementor-menu-cart__close-button').on('click keypress', function(event){
            if(a11yClick(event) === true){
                if(event.type === 'keypress'){
                    jQuery(this).trigger('click');
                    return;
                }
            }}
        );
          

    }

    $('body' ).on( 'updated_shipping_method' , function(){ 
    
        setTimeout( function (){ setSRMessage(SHIPMENT_UPDATED_MSG )} , 1000)
    
    } );


    
});



function setSRMessage(msg){

    if (!msg || msg == undefined){
        msg = '';
    }
    var elem = document.querySelector('#sr-msg');

    // Set HTML content
    elem.innerHTML = msg;
       
}



// Dialog js:
// https://bitsofco.de/accessible-modal-dialog/

/*
var navDialogEl ;
var dialogOverlay;
var myDialog;
*/

function setCartWidgetasDialog(){

    


    cartDialogEl = document.querySelector('.elementor-menu-cart__main');
    cartOverlay = document.querySelector('.elementor-menu-cart__container');

    //cartOverlay.setAttribute("tabindex","-1");
    /*
    cartOverlay.addEventListener("click",function(){
       
      

        var cartOpen =  document.querySelector('.elementor-menu-cart__wrapper .elementor-lightbox').getAttribute("aria-hidden");

        console.log(" cartOverlay: cartOpen = "+ cartOpen + " "+typeof(cartOpen));

        if( cartOpen == "false" ){
            cartDialog.close();
        } 
    })*/

   
    cartDialog = new Dialog(cartDialogEl);
   
    cartDialog.setCloseButton( '.elementor-menu-cart__close-button');

}

/*
function setDialog(){
    
    navDialogEl = document.querySelector('.dialog');
    dialogOverlay = document.querySelector('.dialog-overlay');
	myDialog = new Dialog(navDialogEl, dialogOverlay);
    myDialog.addEventListeners('.open-dialog', '.close-dialog');
    
}
*/
//function Dialog(dialogEl, overlayEl) {
function Dialog(dialogEl) {

   

	this.dialogEl = dialogEl;
	//this.overlayEl = overlayEl;
	this.focusedElBeforeOpen;

	//var focusableEls = this.dialogEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
	this.focusableEls; // = Array.prototype.slice.call(focusableEls);

	this.firstFocusableEl; // = this.focusableEls[0];
	this.lastFocusableEl; // = this.focusableEls[ this.focusableEls.length - 1 ];

	this.close(); // Reset
}


Dialog.prototype.setFocusableEls = function() {

    var focusableEls = this.dialogEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
	this.focusableEls = Array.prototype.slice.call(focusableEls);

	this.firstFocusableEl = this.focusableEls[0];
	this.lastFocusableEl = this.focusableEls[ this.focusableEls.length - 1 ];

}

Dialog.prototype.open = function() {

  	var Dialog = this;

    
    this.setFocusableEls();

	//this.dialogEl.removeAttribute('aria-hidden');
	//this.overlayEl.removeAttribute('aria-hidden');

	this.focusedElBeforeOpen = document.activeElement;

	this.dialogEl.addEventListener('keydown', function(e) {
		Dialog._handleKeyDown(e);
	});
    /*
	this.overlayEl.addEventListener('click', function() {
		Dialog.close();
	});
    */
  
	this.firstFocusableEl.focus();
};

Dialog.prototype.close = function() {

	//this.dialogEl.setAttribute('aria-hidden', true);
	//this.overlayEl.setAttribute('aria-hidden', true);
    //console.log("dialog close called");

	if ( this.focusedElBeforeOpen ) {
		this.focusedElBeforeOpen.focus();
	} else{
        document.querySelector('body').focus();
    }
};


Dialog.prototype._handleKeyDown = function(e) {

	var Dialog = this;
	var KEY_TAB = 9;
	var KEY_ESC = 27;

	function handleBackwardTab() {
		if ( document.activeElement === Dialog.firstFocusableEl ) {
			e.preventDefault();
			Dialog.lastFocusableEl.focus();
		}
	}
	function handleForwardTab() {
		if ( document.activeElement === Dialog.lastFocusableEl ) {
			e.preventDefault();
			Dialog.firstFocusableEl.focus();
		}
	}

	switch(e.keyCode) {
	case KEY_TAB:
		if ( Dialog.focusableEls.length === 1 ) {
			e.preventDefault();
			break;
		} 
		if ( e.shiftKey ) {
			handleBackwardTab();
		} else {
			handleForwardTab();
		}
		break;
	case KEY_ESC:
		Dialog.close();
		break;
	default:
		break;
	}


};



Dialog.prototype.setCloseButton = function (closeDialogSel){
    var Dialog = this;
    var closeDialogEls = document.querySelectorAll(closeDialogSel);
	for ( var i = 0; i < closeDialogEls.length; i++ ) {
		closeDialogEls[i].addEventListener('click', function() {
			Dialog.close();
		});
	}
}

Dialog.prototype.addEventListeners = function(openDialogSel, closeDialogSel) {

	var Dialog = this;

    var openDialogEls = document.querySelectorAll(openDialogSel);
  //  if ( openDialogEls != null && openDialogEls != undefined  ){
        for ( var i = 0; i < openDialogEls.length; i++ ) {
            openDialogEls[i].addEventListener('click', function() { 
                Dialog.open();
            });
        } 
    //}
	

	var closeDialogEls = document.querySelectorAll(closeDialogSel);
	for ( var i = 0; i < closeDialogEls.length; i++ ) {
		closeDialogEls[i].addEventListener('click', function() {
			Dialog.close();
		});
	}

};