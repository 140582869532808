
var focusedElBeforeOpen = null;

jQuery(document).ready(function ($) {

   
        // Check if the page language is Hebrew
        if ($('html').attr('lang') === 'he-IL') {
            // Change the placeholder text of the textarea
            $('#order_comments').attr('placeholder', 'הערות על ההזמנה, לדוגמה, הערות מיוחדות למסירה.');
        }
 
    

    /**
     * Adding screader only text for whatsapp and wase icons.
     */
    $('a[href^="https://ul.waze.com/ul"]').append('<span class="sr-only">ניווט בוויז</span>');
    $('a[href^="https://api.whatsapp.com/send"]').append('<span class="sr-only" >דברו איתנו בוואטצאפ</span>');

    /**
     * 
     *  Adding content id when missing so skip to content will work:
     * 
     */

    

    if ($('#content').length === 0) {
        // If not, find the element with the attribute data-elementor-type="archive"
        var $archiveElement = $('[data-elementor-type="archive"]');
        
        // If such an element exists, set its ID to "content"
        if ($archiveElement.length > 0) {
            $archiveElement.attr('id', 'content');
        }
    }

    /***
     * 
     *  Footer
     * 
     */
    console.log("footer tabindex");
    $("#footer ul.elementor-nav-menu.sm-vertical .menu-item a.elementor-item").attr("tabindex","0")

    /***
     * 
     * image redanet
     * 
     */

    $(".products.elementor-grid .product a img").attr("alt","");
    $(".products.elementor-grid .product a img").attr("aria-hidden","true");
    $(".product_list_widget a img").attr("alt","");
    $(".product_list_widget a img").attr("aria-hidden","true");



    addBGAnimationCtrl();

    /**
     * 
     * Some pages have animatted background (video).
     * This function add Pause/Play toggle button to control the video.
     * 
     */

    function addBGAnimationCtrl(){

        var animationbg = $('.elementor-background-video-container');
       
        if (animationbg.length){

            $(animationbg).each(function(index){

                $(this).parent().append( PPButtonElemntFunc( "playpause"+index));
                $("#playpause"+index).on('click', togglePlay )

            

                video = $('video').get(0);
                
                if ( video.paused ) {
                
                    $("#playpause"+index+" .play").hide();
                    $("#playpause"+index+" .pause").show();
                } else {
                
                    $("#playpause"+index+" .play").show();
                    $("#playpause"+index+" .pause").hide();
                }
                
            })

        }
    }

    
    /***
     * 
     * elementor-accordion-item - questions and answers
     * 
     */

     var accordion = $(".elementor-accordion-item")

     if (accordion.length){
        
        setAccordion();

        $(accordion).each(function(index){

         
         
            $(this).on("click",function(){ setAccordion() })
        });
 
     }

     function setAccordion(){
        var accordion = $(".elementor-accordion-item")

        if (accordion.length){
     
           $(accordion).each(function(index){
   
               var expanded =  $(this).find(".elementor-tab-title").attr("aria-expanded");
               $(this).find(".elementor-accordion-title").attr("aria-expanded", expanded )
               
           })
        }
     }
    

    
    /**
     * 
     *  Elemntor Forms Accessibilty
     * 
     */
     setFormOnInvalid();

     // https://stackoverflow.com/questions/7946714/event-listener-valid-for-html5-forms
     // http://jsfiddle.net/trixta/YndYx/
     //
    
     // This seems to work only when submit button is pressed
     
     if (document.addEventListener) {
         document.addEventListener('invalid', function(e) {
             
             var theInput = e.target;
             var element;
             if (  theInput.type  == 'radio'){
                 element =  $(e.target).closest('div'); //elementor-field-subgroup
 
             }else{
                 element = $(e.target).parent();
             }
           
             var desc =  element.find('.input-feedback');
             var msg = errorForType( theInput.type );
 
             element.addClass("invalid");
             $(theInput).attr("aria-invalid","true");
             $(theInput).attr("aria-describedby",$(desc).attr( 'id' ) );
 
             if ( desc.text() != msg ){
                 desc.text( msg);
             }
             
             //console.log("invaild listener"+ theInput.type )
 
             errorMsgBoxFunc( theInput );
 
         }, true);
     }
     
     $("form button" ).click(function(event){ 
       
         var errorMsgBox = $(this).closest('form').find('div.error-msg-box');
         if (  errorMsgBox.length   ){
             $(errorMsgBox).remove();
         }
       
     });
     
     
     
     $(document).bind('change', function(e){
         if( $(e.target).is(':invalid') ){
 
             $(e.target).parent().addClass('invalid');
             $(e.target).attr("aria-invalid","true");
 
            
         } else {
             $(e.target).parent().removeClass('invalid');
             $(e.target).attr("aria-invalid","false");
 
             var element = $(e.target).parent();
             element.find('.input-feedback').text( "" );
      
         }
     });

     function errorMsgBoxFunc(elem){
       
        //console.log ( 'errorMsgBoxFunc was called ')

        var label = $(elem).parent().find('label').text();
        var id = $(elem).attr("id");
        var name = $(elem).attr("name");
        
       
       // console.log ( "errorMsgBoxFunc " + elem.type + " name:"+name + "" );

        var errorMsgBox = $(elem).closest('form').find('div.error-msg-box');
      
      
        if (  !errorMsgBox.length   ){
            var content = '<div class="error-msg-box" aria-live="assertive" role="alert" ><div class="error-msg-box-inner"> יש לבדוק את השדות הבאים:<ul></ul></div></div>';
            $(elem).closest('form').prepend(content);

        } else if( elem.type == 'radio' ){
          
            
            label = $(elem).closest('div.elementor-field-type-radio').find('label.elementor-field-label').text();
            
            var radioExist =  $(elem).closest('form').find('div.error-msg-box ul li[data-name  ="'+name+'"]');
            
            if ( radioExist.length ){

                return;
            }

        }


        label=  (label.substr(label.length-1,1 ) == "*") ? label.substring( 0 ,label.length-1 ): label; 

        var item = '<li data-name='+name+'><a href ="#'+id + '" >'+label+'</a></li>' ; 

        $(elem).closest('form').find('div.error-msg-box ul').append(item);

    }




    /**
     * 
     * Add controler for swipers slider
     * 
     */
    //https://developers.elementor.com/expose-swiper-in-elementor/
    //https://github.com/elementor/elementor/issues/11067#issuecomment-613521991

    $(window).on('elementor/frontend/init', function () {

        // wait for elementor pro to load
        elementorFrontend.on('components:init', function () {
 
            setTimeout( function(){
               
                //pause play button for #images-slider                
                var BannerCarousel = $('#images-slider .swiper-container');

                //console.log(" BannerCarousel.length="+ BannerCarousel.length)


                var swiperInstance = BannerCarousel.data( 'swiper' );

                
                if( swiperInstance == undefined ){
                    //console.log("undifiend run away!!!")
                    return;
                }


            
                $('#images-slider').append(  PPButtonElemntFunc("main-play-pause") );
                
               setTimeout( function(){

                    if( swiperInstance != undefined){
                        if (  swiperInstance.autoplay.running  ){
                    

                            $('#main-play-pause .play').hide();
                            $('#main-play-pause .pause').show();
        
                        }else{
                            $('#main-play-pause .play').show();
                            $('#main-play-pause .pause').hide();
                        }   
                    }

                 },0 );
                    
                            
                //mySwiper.autoplay.running - Whether autoplay enabled and running
                //mySwiper.slideNext(speed, runCallbacks);	Run transition to next slide

                if( swiperInstance != undefined){
                    $("#main-play-pause").click(function(){

                        if ( swiperInstance.autoplay.running  ){

                            swiperInstance.autoplay.stop();
                            $('#main-play-pause .play').show();
                            $('#main-play-pause .pause').hide();

                        }else{
                            swiperInstance.slideNext();
                            swiperInstance.autoplay.start();
                            $('#main-play-pause .play').hide();
                            $('#main-play-pause .pause').show();
                        }
                    });

                    /* making a event to next and previus button so the content will be send to aria-live div*/
                    $('#images-slider .elementor-swiper-button').on('click', function(event){

                        /*       swiperInstance.on ('slideChangeTransitionEnd',function(){}*/

                        var msg = $("#images-slider .swiper-slide-active .elementor-slide-description").text();                     

                        $("#sr-msg").text(msg);
                      


                    })

                    $('#images-slider .swiper-pagination-bullet' ).each(
                        function(index){
                            $(this ).attr('data-ayl-slider-index',index);

                            
                        }
                    )

                    $('#images-slider .swiper-pagination-bullet' ).on('click', function(event){

                        //console.log('bullet was called');

                        var indx =  $(this ).attr('data-ayl-slider-index');
                        var slide =  $("#images-slider .swiper-slide[data-swiper-slide-index="+indx+"]" ).get(0);
                       

                        var msg = $(slide).find(".swiper-slide-image").attr("alt");                     

                        $("#sr-msg").text(msg);


                    });

                    $('#images-slider .elementor-swiper-button' ).on('click', function(event){

                        //console.log('prev-next was called');

                        var indx = swiperInstance.realIndex ;           //$(this ).attr('data-ayl-slider-index');
                        var slide =  $("#images-slider .swiper-slide[data-swiper-slide-index="+indx+"]" ).get(0);
                       

                        var msg = $(slide).find(".swiper-slide-image").attr("alt");                     

                        $("#sr-msg").text(msg);


                    });

                }
                           

            },500)

        
        });
    });

    
       
}); // end of load

function PPButtonElemntFunc(id){

    //console.log("PPButtonElemntFunc was called with "+id);

    var  buttonText = '<div class="pause-play-wrap"><button id="'+id+'" class="button">';
    /*
    buttonText =  buttonText+'<div class="play pause-play elementor-icon" ><span class="sr-only">הפעל אנימציה</span><svg aria-hidden="true" class="e-font-icon-svg e-far-play-circle"><use xlink:href="#far-play-circle"></use></svg></div>';
    buttonText =  buttonText+'<div class="pause pause-play elementor-icon" ><span class="sr-only">עצור אנימציה</span><svg aria-hidden="true" class="e-font-icon-svg e-far-pause-circle"><use xlink:href="#far-pause-circle"></use></svg></div></button></div>';
*/
    buttonText =  buttonText+'<div class="play pause-play elementor-icon" ><span class="sr-only">הפעל אנימציה</span><svg aria-hidden="true" class="" viewBox="0 0 512 512"><path d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"></path></svg></div>'
    buttonText =  buttonText+'<div class="pause pause-play elementor-icon" ><span class="sr-only">עצור אנימציה</span><svg aria-hidden="true" class=""  viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm96-280v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16zm-112 0v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16z"></path></use></svg></div></button></div>';

    return buttonText;

    /*
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
    */
}

var video //= $('video').get(0);
// Add functions here

// togglePlay toggles the playback state of the video.
// If the video playback is paused or ended, the video is played
// otherwise, the video is paused
function togglePlay() {
    //console.log("toggleplay");
    if (video.paused || video.ended) {
      video.play();
      jQuery(this).find(".play").hide();
      jQuery(this).find(".pause").show();
    } else {
      video.pause();
      jQuery(this).find(".play").show();
      jQuery(this).find(".pause").hide();
    }
  }





  
function setFormOnInvalid(){

    
    var form = document.querySelector('.elementor-form');

    if (form == null || form == undefined){
        return;
    }

    var elements = form.elements;   
    var elemsArr1 = Array.from(elements);

    elemsArr1.forEach(function (obj, index) {
        
        // elemntor use required = required instead of just required.
         var req = obj.getAttribute("aria-required")
        if(req == "true"){
            obj.required = true;
        }

        if( typeForDesc( obj.type ) ){

            

            var parent = obj.parentNode;
            var describedID  = 'desc' + index ; 
            var content = parent.innerHTML;
            var descSpan = '<span id='+ describedID  +' aria-live="assertive" class="input-feedback" role="alert" ></span>';

    
            parent.innerHTML = content + descSpan;
        } 

        
        if ( obj.type == 'radio'  ){
          
            var parent = obj.closest('div');

         
           
            if(parent ){
                if ( parent.querySelector( ".input-feedback" ) == null ){
                   // console.log('error massage does not fonud');
                    var describedID  = 'desc' + index ; 
                    var content = parent.innerHTML;
                    var descSpan = '<span id='+ describedID  +' aria-live="assertive" class="input-feedback" role="alert" ></span>';
                    parent.innerHTML = content + descSpan;

                }

               
            }   

        }

      
        

    });

}

function typeForDesc(type){
   // console.log("typeForDesc was called with:"+type);
    switch (type){
        case 'text':
        case 'email':
        case 'tel':
        case 'select-one':
        case 'textarea':
        case 'number':
        case 'checkbox':
        case 'date':
       
            return true;
            break;
        default:
            return false;
            break;


    }


}

function errorForType(type){
    //console.log('errorForType was called with: '+type);
    switch (type){
        case 'text':
        case 'textarea':
            return 'חובה למלא שדה זה';
            break;
        case 'email':
            return 'יש לכלול סימן @ בכתובת האימייל וסיומת תקינה, לדוגמה: dan@examplae.com';
        case 'tel':
            return 'מותר להשתמש רק במספרים ותווי טלפון (#, -, *).';
            break;
        case 'select':
        case 'select-one':
        case 'radio':
            return 'יש לבחור אחת מהאפשריות';
            break;
        case 'number':
            return 'מותר להשתמש רק במספרים';
            break;
        case 'checkbox':
             return 'חובה לסמן שדה זה';
            break;
        case 'date':
                return 'יש להכניס תאריך בפורמט yyyy-mm-dd';
               break;
        default:
            return false;
            break;

    }
}

function a11yClick(event){

    
  
    if(event.type === 'click'){
        
        return true;
    } else if(event.type === 'keypress'){
       
        var code = event.charCode || event.keyCode;
        if(code === 32){
                event.preventDefault(); // don't scroll the page
        }
        
        if((code === 32) || (code === 13)){
            return true;
        }
    }else{
        //console.log ('a11yClick false');
        return false;
    }
}

    //    $('#fake-button').on('click keypress', function(event){if(a11yClick(event) === true){// do magic javascript stuff}});




