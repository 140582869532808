


var dialogWidetList = {};

/*

this.dialogEl.addEventListener('keydown', function(e) {
		Dialog._handleKeyDown(e);
	});

*/




document.body.addEventListener('keydown', function(event) {
    var keyName = event.code;
    var KEY_ESC = 'Escape';//27;

    if (keyName ==  KEY_ESC ){
       
        jQuery('.dialog-widget').each( function(){
          
            displayValue = jQuery(this ).css('display');
            if ( displayValue != "none"){
                jQuery(this ).find('.dialog-close-button').trigger("click");
            }

        })
        
       
    }
});


jQuery(document).ready(function ($) {

//***** POPUP *****/

    $( document ).on( 'elementor/popup/show', function( event, id, instance) {
       

        

            $('.dialog-widget').attr("aria-modal","true");
            $('.dialog-widget').attr("role","dialog");
           // $('div.dialog-close-button').on('click',closeDialogWidget);

           // $('div.dialog-close-button').attr("role","button");
            //$('div.dialog-close-button').attr("tabindex","0");

            $('#elementor-popup-modal-'+id+' .dialog-widget-content' ).attr("tabindex","-1");
                        
            
            if ( !dialogExits(id) ){

                $('#elementor-popup-modal-'+id+' .dialog-close-button').prepend('<button><span class="sr-only">סגור</span></button>');
                
                $('#elementor-popup-modal-'+id+' .div.dialog-close-button').on('click keypress', function(event){
                    if(a11yClick(event) === true){
                        if(event.type === 'keypress'){
                            jQuery(this).trigger('click');
                            return;
                        }
                    }}
                );
            

                dialogWidetList [ 'popup'+id ] =  setDialogWidget( '#elementor-popup-modal-'+id+' .dialog-widget-content' );
                dialogWidetList [ 'popup'+id ].setCloseButton( '#elementor-popup-modal-'+id+'.dialog-lightbox-close-button');
            }

            
            dialogWidetList[ 'popup'+id ].open();
            $('#elementor-popup-modal-'+id+' .dialog-widget-content' ).focus();
           
            
            $("#sr-msg").text("פופ-אפ נפתח");



    } );

    $( document ).on( 'elementor/popup/hide',function ( event, id, instance ){

        if ( dialogExits(id) ){
         dialogWidetList [ 'popup'+id ].close();
        }
    } );


    function setDialogWidget(selector){

    


        var widgetDialogEl = document.querySelector(selector);   
       
        return new Dialog(widgetDialogEl);
       
    
    }

    function dialogExits(id){
        
        var dialog = 'popup'+id  in  dialogWidetList;

        if (dialog == undefined || dialog == null || !dialog){
           
            return false;
        }

        return true;

    }

     

});